<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="9"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title">
              {{ eventPackage.stringID }} - {{ eventPackage.name }}
            </h1>
            <p class="log_info">
              Created by {{ eventPackage.createdBy ? eventPackage.createdBy.name : '' }} on {{ dateFormatWithTime(eventPackage.createdAt) }}<br>Last updated on {{ dateFormatWithTime(eventPackage.updatedAt) }}
            </p>
          </b-col>
        </b-row>
      </b-col>

      <!-- Content Right -->
      <b-col
        class="content-header-right text-md-right d-md-block d-none mb-1"
        md="3"
        cols="12"
      >
        <div class="user_block ac_type">
          <div class="user_avatar" />
          <div class="user_info">
            <h6>Status</h6>
            <h5>{{ eventPackage.status }}</h5>
          </div>
          <div class="action_button">
            <b-button
              variant="flat-primary"
              :to="{ name: 'appointment-event-packages-edit', params: { id: $route.params.id } }"
            >
              <feather-icon
                icon="EditIcon"
                class="mr-50"
                size="16"
              />
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>
    <!-- Table Container Card -->

    <b-card
      class="mb-3 p-2 calllog-details"
    >
      <b-row
        class="content-header"
      >

        <!-- Content Left -->
        <b-col
          class="content-header-left mb-2"
          cols="12"
          md="9"
        >
          <h3>Package Details</h3>
        </b-col>

        <b-col
          class="content-header-right text-md-right d-md-block d-none mb-1"
          md="3"
          cols="12"
        >
          <b-button
            variant="flat-primary"
            :to="{ name: 'appointment-event-packages-edit', params: { id: $route.params.id } }"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle">Edit</span>
          </b-button>
        </b-col>
      </b-row>
      <b-table-simple
        borderless
        responsive
      >
        <b-tbody>
          <b-tr>
            <b-th>
              Name
            </b-th>
            <b-td>{{ eventPackage.name }}</b-td>
            <b-th>
              Event
            </b-th>
            <b-td>{{ eventPackage.event ? eventPackage.event.name : '' }}</b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Available Quantity
            </b-th>
            <b-td>{{ eventPackage.availableQuantity }}</b-td>
            <b-th>
              Pricing (S$)
            </b-th>
            <b-td>{{ eventPackage.price }}</b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Allow Waiting List
            </b-th>
            <b-td>{{ eventPackage.waitingList === true ? 'Yes' : 'No' }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>

    <b-card
      class="mb-3 p-2"
    >
      <b-row
        class="content-header"
      >

        <!-- Content Left -->
        <b-col
          class="content-header-left mb-2"
          cols="12"
          md="9"
        >
          <h3>Custom Form</h3>
        </b-col>

        <b-col
          class="content-header-right text-md-right d-md-block d-none mb-1"
          md="3"
          cols="12"
        >
          <b-button
            variant="flat-primary"
            :to="{ name: 'appointment-event-packages-edit', params: { id: $route.params.id } }"
            class="button_no_padding"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle">Edit</span>
          </b-button>
        </b-col>
      </b-row>
      <div
        v-for="(opt, key) in eventPackage.customFields"
        :key="key"
      >
        <b-row
          class="content-header mr-50"
        >
          <!-- Content Left -->
          <b-col
            class="content-header-left"
            cols="12"
            md="2"
          >
            <p><strong>Component Type</strong></p>
          </b-col>
          <b-col
            class="content-header-left"
            cols="12"
            md="10"
          >
            <p>{{ resolveOptionValue(opt.type) }}</p>
          </b-col>
        </b-row>
        <div
          v-if="opt.type == 'file'"
        >
          <b-row
            class="content-header mr-50"
          >
            <b-col
              class="content-header-left"
              cols="12"
              md="2"
            >
              <p><strong>Uploaded File</strong></p>
            </b-col>
            <b-col
              class="content-header-left"
              cols="12"
              md="10"
            >
              <b-img
                thumbnail
                fluid
                width="200px"
                height="auto"
                :src="opt.file"
              />
            </b-col>
          </b-row>
        </div>
        <div
          v-if="opt.type == 'title-and-text'"
        >
          <b-row
            class="content-header mr-50"
          >
            <!-- Content Left -->
            <b-col
              class="content-header-left"
              cols="12"
              md="2"
            >
              <p><strong>Title</strong></p>
            </b-col>
            <b-col
              class="content-header-left"
              cols="12"
              md="10"
            >
              <p>{{ opt.label }}</p>
            </b-col>
          </b-row>
          <b-row
            class="content-header mr-50"
          >
            <!-- Content Left -->
            <b-col
              class="content-header-left"
              cols="12"
              md="2"
            >
              <p><strong>Text</strong></p>
            </b-col>
            <b-col
              class="content-header-left"
              cols="12"
              md="10"
            >
              <p>{{ opt.description ? opt.description : '-' }}</p>
            </b-col>
          </b-row>
        </div>
        <div
          v-if="opt.type == 'short-answer' || opt.type == 'long-answer' || opt.type == 'single-select' || opt.type == 'multi-select'"
        >
          <b-row
            class="content-header mr-50"
          >
            <!-- Content Left -->
            <b-col
              class="content-header-left"
              cols="12"
              md="2"
            >
              <p><strong>Label</strong></p>
            </b-col>
            <b-col
              class="content-header-left"
              cols="12"
              md="10"
            >
              <p>{{ opt.label }}</p>
            </b-col>
          </b-row>
          <b-row
            class="content-header mr-50"
          >
            <!-- Content Left -->
            <b-col
              class="content-header-left"
              cols="12"
              md="2"
            >
              <p><strong>Description</strong></p>
            </b-col>
            <b-col
              class="content-header-left"
              cols="12"
              md="10"
            >
              <p>{{ opt.description ? opt.description : '-' }}</p>
            </b-col>
          </b-row>
          <b-row
            v-if="opt.extraOptions.length > 0"
            class="content-header mr-50"
          >
            <!-- Content Left -->
            <b-col
              class="content-header-left"
              cols="12"
              md="2"
            >
              <p><strong>Add Selection Options</strong></p>
            </b-col>
            <b-col
              class="content-header-left"
              cols="12"
              md="10"
            >
              <span
                v-for="(option, index) in opt.extraOptions"
                :key="index"
              >
                {{ option.key }} <span v-if="index + 1 != opt.extraOptions.length">, </span>
              </span>
            </b-col>
          </b-row>
          <b-row
            class="content-header mr-50"
          >
            <!-- Content Left -->
            <b-col
              class="content-header-left"
              cols="12"
              md="2"
            >
              <p><strong>Required?</strong></p>
            </b-col>
            <b-col
              class="content-header-left"
              cols="12"
              md="10"
            >
              <p>{{ opt.required === 'yes' ? 'Yes' : 'No' }}</p>
            </b-col>
          </b-row>
        </div>
        <b-row
          v-if="key + 1 != eventPackage.customFields.length"
          class="content-header mr-50"
        >
          <b-col
            class="content-header-left"
            cols="12"
          >
            <hr class="dividerHR">
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BButton, BCol, BTableSimple, BTbody, BTr, BTh, BTd, BImg,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BRow,
    BButton,
    BCol,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BImg,
  },
  data() {
    return {
      eventPackage: {},
    }
  },
  created() {
    this.$http.get(`appointment/event-packages/${this.$route.params.id}/show`)
      .then(response => {
        this.eventPackage = response.data ?? {}
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  methods: {
    resolveOptionValue(val) {
      if (val === 'file') return 'Image'
      if (val === 'title-and-text') return 'Title and Text'
      if (val === 'short-answer') return 'Short Answer Field'
      if (val === 'long-answer') return 'Long Answer Field'
      if (val === 'multi-select') return 'Selection List Field (Add-Ons)'
      if (val === 'single-select') return 'Single Checkbox Field'
      return '-'
    },
  },
}
</script>
